import { call, takeLatest, put, all, select, take } from "redux-saga/effects";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";
import UserApi, {
  UpdateUserRequest,
  LeaveWorkspaceRequest,
  FetchXmUserResponse,
  FetchXmUserAppMenuResponse,
  fetchXmAppInfoResponse,
} from "@appsmith/api/UserApi";
import { AUTH_LOGIN_URL } from "constants/routes";
import { ApiResponse } from "api/ApiResponses";
import { validateResponse, callAPI } from "./ErrorSagas";
import { logoutUserSuccess, logoutUserError } from "actions/userActions";
import AnalyticsUtil from "utils/AnalyticsUtil";
import { ERROR_CODES } from "@appsmith/constants/ApiConstants";
import { User } from "constants/userConstants";
import { flushErrorsAndRedirect } from "actions/errorActions";
import localStorage from "utils/localStorage";
import log from "loglevel";

import { getCurrentUser } from "selectors/usersSelectors";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import { getXmUserPhone } from "selectors/xmUserSelectors";

export function* getXmAppStatusSaga(action: any) {
  try {
    const pageId = action.payload;
    const response: fetchXmAppInfoResponse = yield call(
      UserApi.getXmAppInfo,
      pageId,
    );
    yield put({
      type: ReduxActionTypes.FETCH_XM_APP_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReduxActionTypes.FETCH_XM_APP_INFO_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* getCurrentXmUserSaga() {
  try {
    const response: FetchXmUserResponse = yield call(UserApi.getCurrentXmUser);
    yield put({
      type: ReduxActionTypes.FETCH_XM_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReduxActionTypes.FETCH_XM_USER_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* getXmUserAppMenuSaga(action: any) {
  try {
    const applicationId: string = yield select(getCurrentApplicationId);
    const userPhone: string = yield select(getXmUserPhone);
    const xmUserPhone = JSON.parse(localStorage.getItem("XM_USER") || "{}")
      ?.userPhone;
    const response: FetchXmUserAppMenuResponse = yield call(
      UserApi.getXmUserAppMenu,
      {
        menuId: 0,
        userPhone: userPhone || xmUserPhone,
        appCode: `LC-${applicationId}`,
      },
    );
    yield put({
      type: ReduxActionTypes.FETCH_XM_USER_APP_MENU_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReduxActionTypes.FETCH_XM_USER_APP_MENU_ERROR,
      payload: {
        error,
      },
    });
  }
}
export function* updateUserDetailsSaga(action: ReduxAction<UpdateUserRequest>) {
  try {
    const { email, name, role, useCase } = action.payload;
    const response: ApiResponse = yield callAPI(UserApi.updateUser, {
      email,
      name,
      role,
      useCase,
    });
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.UPDATE_USER_DETAILS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_USER_DETAILS_ERROR,
      payload: (error as Error).message,
    });
  }
}

export function* logoutSaga(action: ReduxAction<{ redirectURL: string }>) {
  try {
    const redirectURL = action.payload?.redirectURL;
    const response: ApiResponse = yield call(UserApi.logoutUser);
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      AnalyticsUtil.reset();
      const currentUser: User | undefined = yield select(getCurrentUser);
      yield put(logoutUserSuccess(!!currentUser?.emptyInstance));
      localStorage.clear();
      yield put(flushErrorsAndRedirect(redirectURL || AUTH_LOGIN_URL));
    }
  } catch (error) {
    log.error(error);
    yield put(logoutUserError(error));
  }
}

export function* waitForFetchUserSuccess() {
  const currentUser: string | undefined = yield select(getCurrentUser);
  if (!currentUser) {
    yield take(ReduxActionTypes.FETCH_XM_USER_SUCCESS);
  }
}

export default function* xmUserSagas() {
  yield all([
    takeLatest(ReduxActionTypes.FETCH_XM_USER_INIT, getCurrentXmUserSaga),
    takeLatest(
      ReduxActionTypes.FETCH_XM_USER_APP_MENU_INIT,
      getXmUserAppMenuSaga,
    ),
    takeLatest(ReduxActionTypes.FETCH_XM_APP_INFO, getXmAppStatusSaga),
    takeLatest(ReduxActionTypes.LOGOUT_USER_INIT, logoutSaga),
    takeLatest(
      ReduxActionTypes.UPDATE_USER_DETAILS_INIT,
      updateUserDetailsSaga,
    ),
    takeLatest(ReduxActionTypes.LEAVE_WORKSPACE_INIT, leaveWorkspaceSaga),
  ]);
}

export function* leaveWorkspaceSaga(
  action: ReduxAction<LeaveWorkspaceRequest>,
) {
  try {
    const request: LeaveWorkspaceRequest = action.payload;
    const response: ApiResponse = yield call(UserApi.leaveWorkspace, request);
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.GET_ALL_APPLICATION_INIT,
      });
    }
  } catch (error) {
    // do nothing as it's already handled globally
  }
}
