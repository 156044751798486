import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionTypes,
} from "@appsmith/constants/ReduxActionConstants";
import { User } from "constants/userConstants";

const initialState: XmUsersReduxState = {
  userId: 0,
  userName: "",
  userPhone: "",
  userEmail: "",
  icon: "",
  departName: "",
  positionName: "",
  isChangePwd: false,
  xmIframeToken: "",
  appMenu: "",
  appInfo: "",
};

const xmUsersReducer = createReducer(initialState, {
  [ReduxActionTypes.FETCH_XM_USER_INIT]: (state: XmUsersReduxState) => ({
    ...state,
  }),
  [ReduxActionTypes.PROP_PANE_MOVED]: (
    state: XmUsersReduxState,
    action: ReduxAction<PropertyPanePositionConfig>,
  ) => ({
    ...state,
    propPanePreferences: {
      isMoved: true,
      position: {
        ...action.payload.position,
      },
    },
  }),
  [ReduxActionTypes.FETCH_XM_USER_SUCCESS]: (
    state: XmUsersReduxState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [ReduxActionTypes.FETCH_XM_USER_APP_MENU_SUCCESS]: (
    state: XmUsersReduxState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      appMenu: action.payload,
    };
  },
  [ReduxActionTypes.FETCH_XM_APP_INFO_SUCCESS]: (
    state: XmUsersReduxState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      appInfo: action.payload,
    };
  },
  [ReduxActionTypes.SET_CURRENT_USER_SUCCESS]: (
    state: XmUsersReduxState,
    action: ReduxAction<User>,
  ) => ({
    ...state,
    current: action.payload,
  }),
  [ReduxActionTypes.LOGOUT_XM_USER_SUCCESS]: (state: XmUsersReduxState) => {
    return {
      ...state,
      xmIframeToken: "",
    };
  },
  [ReduxActionTypes.LOGIN_XM_USER_SUCCESS]: (
    state: XmUsersReduxState,
    action: ReduxAction<any>,
  ) => {
    return {
      ...state,
      xmIframeToken: action.payload,
    };
  },
});

export interface PropertyPanePositionConfig {
  isMoved: boolean;
  position: {
    left: number;
    top: number;
  };
}
export interface XmUsersReduxState {
  userId: number;
  userName: string;
  userPhone: string;
  userEmail: string;
  icon: string;
  departName: string;
  positionName: string;
  isChangePwd: boolean;
  xmIframeToken: string;
  appMenu: any;
  appInfo: any;
}

export default xmUsersReducer;
