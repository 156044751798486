import { AppState } from "reducers";

export const getCurrentXmUser = (state: AppState): any => state.ui.xmUser;
export const getXmUserPhone = (state: AppState): any =>
  state.ui.xmUser?.userPhone;
export const getXmUserAppMenu = (state: AppState): any =>
  state.ui.xmUser?.appMenu;
export const getXmIframeToken = (state: AppState): any =>
  state.ui.xmUser?.xmIframeToken;
export const getXmAppInfo = (state: AppState): any => state.ui.xmUser?.appInfo;
export const getXmAuthRequire = (state: AppState): any => {
  const appInfo = state.ui.xmUser?.appInfo;
  // 如果存在 appInfo 并且 authType 值为1或者2时，需要鉴权登录，并请求用户信息、菜单信息
  if (appInfo && [1, 2].includes(appInfo.authType)) {
    return true;
  }
  return false;
};
